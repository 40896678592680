
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import VerticalAligned from "@/components/VerticalAligned.vue";
import BrandCarousel from "@/components/BrandCarousel.vue";

import AboutParallaxContent, {
  ParallaxBackground,
} from "@/components/about/AboutParallaxContent.vue";
import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
import IntroVideo from "@/components/IntroVideo.vue";
import BannerIntro from "@/components/BannerIntro.vue";
import TodayAbout from "@/components/about/TodayAbout.vue";
import ActualityAbout from "@/components/about/ActualityAbout.vue";
import BannerAbout from "@/components/about/BannerAbout.vue";

@Component({
  metaInfo(this: About): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    parallax: AboutParallaxContent,
    "vertical-aligned": VerticalAligned,
    "brand-carousel": BrandCarousel,
    IntroVideo,
    BannerIntro,
    TodayAbout,
    ActualityAbout,
    BannerAbout,
  },
})
export default class About extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Nosotros",
      description: "Luna Trend",

      // parallax: {
      //   background: this.getAssetPath("./about/banner.jpg"),
      //   backgroundSize: "contain",
      //   backgroundMobile: this.getAssetPath("./about/banner.jpg"),
      //   backgroundMobileSize: "200%",
      // } as ParallaxBackground,

      // parallax2: {
      //   background: this.getAssetPath("./about/banner2.jpg"),
      //   backgroundSize: "contain",
      //   backgroundMobile: this.getAssetPath("./about/banner2.jpg"),
      //   backgroundMobileSize: "200%",
      // } as ParallaxBackground,

      parallax3: {
        background: this.getAssetPath("./about/banner3.jpg"),
        backgroundSize: "contain",
        backgroundMobile: this.getAssetPath("./about/banner3.jpg"),
        backgroundMobileSize: "200%",
      } as ParallaxBackground,
    };
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }
}
