
import { Component, Prop, Vue } from "vue-property-decorator";
import VerticalAligned from "@/components/VerticalAligned.vue";

export interface ParallaxBackground {
  background: string;
  backgroundSize: string;
  backgroundMobile: string;
  backgroundMobileSize: string;
}

@Component({
  components: { "vertical-aligned": VerticalAligned },
})
export default class Parallax extends Vue {
  @Prop() private background!: string;
  @Prop() private backgroundSize!: string;
  @Prop() private backgroundMobile!: string;
  @Prop() private backgroundMobileSize!: string;
  @Prop() private height!: string;
  @Prop() private heightMobile!: string;
  @Prop() private path;
}
