
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class IntroVideo extends Vue {
  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

  get video() {
    return this.$store.state.website?.raw_html_content1;
  }
}
