import { render, staticRenderFns } from "./AboutParallaxContent.vue?vue&type=template&id=40c9b37b&scoped=true&"
import script from "./AboutParallaxContent.vue?vue&type=script&lang=ts&"
export * from "./AboutParallaxContent.vue?vue&type=script&lang=ts&"
import style0 from "./AboutParallaxContent.vue?vue&type=style&index=0&id=40c9b37b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c9b37b",
  null
  
)

export default component.exports