
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import { IProduct } from "@/services/catalog.service";

@Component
export default class TodayAbout extends Vue {
  @Prop() private title;
  @Prop() private path;
  @Prop() private bg;
  @Prop() private height;
  @Prop() private heightMobile;
  // @Prop() private model!: IProduct;

  data() {
    return {};
  }
}
